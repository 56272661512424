<template>
  <div class="main_container">
    <div class="row mb-3">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <router-link :to="'/analyses/comptabilite/'">
          Comptabilité >
        </router-link>
        <span>Création exercice comptable</span>
      </div>
    </div>
    <div class="row mb-5 mt-2">
      <div class="col-md-6 an-title mx-auto card title-card title">
        <h3 class="text-center text-uppercase ">Ajouter un exercice comptable</h3>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row">
      <div class="col-md-8 mx-auto">
        <form>
          <!-- ligne année fiscale -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Année fiscale</label>
            </div>
            <div class="form-group col-md-8">
              <select class="form-control"
                      name=""
                      id=""
                      v-on:click="removeSPan('annee_fiscale')"
                      v-model="form.annee_fiscale">
                <option>2020</option>
                <option>2021</option>
                <option>2022</option>
              </select>
              <span v-if="form.errors().has('annee_fiscale') && affiche.annee_fiscale !== 'annee_fiscale'"
                    v-text="form.errors().get('annee_fiscale')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Capitaux Propres -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Capitaux propres</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('capitaux_propres')"
                     v-model="form.capitaux_propres" />
              <span v-if="form.errors().has('capitaux_propres') && affiche.capitaux_propres !== 'capitaux_propres'"
                    v-text="form.errors().get('capitaux_propres')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Endettement Net -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Endettement net</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('endettement_net')"
                     v-model="form.endettement_net" />
              <span v-if="form.errors().has('endettement_net') && affiche.endettement_net !== 'endettement_net'"
                    v-text="form.errors().get('endettement_net')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Actif circulant -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Actifs circulants</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('actif_circulant')"
                     v-model="form.actif_circulant" />
              <span v-if="form.errors().has('actif_circulant') && affiche.actif_circulant !== 'actif_circulant'"
                    v-text="form.errors().get('actif_circulant')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Actif passant -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Passifs circulants</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('actif_passant')"
                     v-model="form.actif_passant" />
              <span v-if="form.errors().has('actif_passant') && affiche.actif_passant !== 'actif_passant'"
                    v-text="form.errors().get('actif_passant')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Besoins Financier de l'Entretien Routier -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Besoins financiers de l'entretien routier</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('besoins_financiers_entretien_routier')"
                     v-model="form.besoins_financiers_entretien_routier" />
              <span
                v-if="form.errors().has('besoins_financiers_entretien_routier') && affiche.besoins_financiers_entretien_routier !== 'besoins_financiers_entretien_routier'"
                v-text="form.errors().get('besoins_financiers_entretien_routier')"
                class="errorMsg"
              >
              </span>
            </div>
          </div>
          <!-- Ligne Charge de Fonctionnement -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Charge de fonctionnement</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('charge_fonctionnement')"
                     v-model="form.charge_fonctionnement" />
              <span v-if="form.errors().has('charge_fonctionnement') && affiche.charge_fonctionnement !== 'charge_fonctionnement'"
                    v-text="form.errors().get('charge_fonctionnement')"
                    class="errorMsg">
              </span>
            </div>
          </div>
          <!-- Ligne Ressources Mobilisées -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Ressources mobilisées <span class="min-desc">(Hors levées de fonds et recettes de péages et pesages)</span></label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('ressources_mobilisees_hors_levee')"
                     v-model="form.ressources_mobilisees_hors_levee" />
              <span
                v-if="form.errors().has('ressources_mobilisees_hors_levee') && affiche.ressources_mobilisees_hors_levee !== 'ressources_mobilisees_hors_levee'"
                v-text="form.errors().get('ressources_mobilisees_hors_levee')"
                class="errorMsg"
              >
              </span>
            </div>
          </div>
          <!-- Ligne Provisions Constituées -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Provisions constituées</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('provisions_constituees')"
                     v-model="form.provisions_constituees" />
              <span
                v-if="form.errors().has('provisions_constituees') && affiche.provisions_constituees !== 'provisions_constituees'"
                v-text="form.errors().get('provisions_constituees')"
                class="errorMsg"
              >
              </span>
            </div>
          </div>
          <!-- Ligne Engagements Prévus -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Engagements prévus</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('engagements_prevus')"
                     v-model="form.engagements_prevus" />
              <span v-if="form.errors().has('engagements_prevus') && affiche.engagements_prevus !== 'engagements_prevus'"
                    v-text="form.errors().get('engagements_prevus')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Ressources Mobilisées -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Ressources mobilisées</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('ressources_mobilisees')"
                     v-model="form.ressources_mobilisees" />
              <span v-if="form.errors().has('ressources_mobilisees') && affiche.ressources_mobilisees !== 'ressources_mobilisees'"
                    v-text="form.errors().get('ressources_mobilisees')"
                    class="errorMsg">
              </span>
            </div>
          </div>
          <!-- Ligne Ressources Prévues -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Ressources prévues</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('ressources_prevus')"
                     v-model="form.ressources_prevus" />
              <span v-if="form.errors().has('ressources_prevus') && affiche.ressources_prevus !== 'ressources_prevus'"
                    v-text="form.errors().get('ressources_prevus')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <!-- Ligne Ressources affectées mobilisées -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Ressources affectées mobilisées</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('ressources_affectees_mobilisees')"
                     v-model="form.ressources_affectees_mobilisees" />
              <span
                v-if="form.errors().has('ressources_affectees_mobilisees') && affiche.ressources_affectees_mobilisees !== 'ressources_affectees_mobilisees'"
                v-text="form.errors().get('ressources_affectees_mobilisees')"
                class="errorMsg"
              >
              </span>
            </div>
          </div>
          <!-- Ligne Budget cible -->
          <div class="form-row row">
            <div class="form-group col-md-4">
              <label for=""
                     class="req">Budget cible</label>
            </div>
            <div class="form-group col-md-8">
              <input class="form-control"
                     type="number"
                     v-on:click="removeSPan('budget_cible')"
                     v-model="form.budget_cible" />
              <span v-if="form.errors().has('budget_cible') && affiche.budget_cible !== 'budget_cible'"
                    v-text="form.errors().get('budget_cible')"
                    class="errorMsg"> </span>
            </div>
          </div>
          <div class="form-row row">
            <div class="form-group col-md-6 mx-auto text-center">
              <button class="btn btn-f-blue mr-5"
                      type="button"
                      @click="submit"><i class="flaticon flaticon-floppy-disk"></i>&nbsp;&nbsp; Enregistrer</button>

              <button type="reset"
                      class="btn btn-f-blue cancle_btn"
                      data-toggle="modal"
                      data-target="#exampleModal"><i class="flaticon flaticon-cancel"></i>&nbsp;&nbsp; Annuler</button>
            </div>
          </div>
        </form>
        <!-- modal box -->
        <div class="modal fade"
             id="exampleModal"
             tabindex="-1"
             role="dialog"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div class="modal-dialog"
               role="document">
            <div class="modal-content">
              <div class="modal-header ">
                <h5 class="modal-title text-uppercase"
                    id="exampleModalLabel">Annulation</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Cette action est irréversible, êtes-vous sure de vouloir annuler ?</p>
              </div>
              <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal">Non</button>
                <button type="button"
                        @click="goBack()"
                        data-dismiss="modal"
                        class="btn btn-primary">Oui</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import { mapActions, mapGetters, mapMutations } from "vuex"
export default {
  name: "addComptable",
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form: form({
      annee_fiscale: "",
      capitaux_propres: "",
      endettement_net: "",
      actif_circulant: "",
      actif_passant: "",
      besoins_financiers_entretien_routier: "",
      charge_fonctionnement: "",
      ressources_mobilisees_hors_levee: "",
      provisions_constituees: "",
      engagements_prevus: "",
      ressources_mobilisees: "",
      ressources_prevus: "",
      ressources_affectees_mobilisees: "",
      budget_cible: ""
    }).rules({
      annee_fiscale: "required",
      capitaux_propres: "required",
      endettement_net: "required",
      actif_circulant: "required",
      actif_passant: "required",
      besoins_financiers_entretien_routier: "required",
      charge_fonctionnement: "required",
      ressources_mobilisees_hors_levee: "required",
      provisions_constituees: "required",
      engagements_prevus: "required",
      ressources_mobilisees: "required",
      ressources_prevus: "required",
      ressources_affectees_mobilisees: "required",
      budget_cible: "required"
    }),
    affiche: {
      annee_fiscale: "annee_fiscale:true",
      capitaux_propres: "capitaux_propres:true",
      endettement_net: "endettement_net:true",
      actif_circulant: "actif_circulant:true",
      actif_passant: "actif_passant:true",
      besoins_financiers_entretien_routier: "besoins_financiers_entretien_routier:true",
      charge_fonctionnement: "charge_fonctionnement:true",
      ressources_mobilisees_hors_levee: "ressources_mobilisees_hors_levee:true",
      provisions_constituees: "provisions_constituees:true",
      engagements_prevus: "engagements_prevus:true",
      ressources_mobilisees: "ressources_mobilisees:true",
      ressources_prevus: "ressources_prevus:true",
      ressources_affectees_mobilisees: "ressources_affectees_mobilisees:true",
      budget_cible: "budget_cible:true"
    }
  }),
  watch: {
    successPost() {
      if (this.successPost) {
        this.notif.message = this.successPost
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$router.push({ name: "Comptabilite" })
          }.bind(this),
          3000
        )
      }
      if (this.errorsLevee) {
        this.notif.message = this.errorsLevee
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    }
  },
  computed: {
    ...mapGetters(["successPost", "errorsPost"])
  },
  methods: {
    ...mapActions(["saveEFiscaux"]),
    ...mapMutations(["setsuccessPost", "setErrorPost"]),
    submit() {
      if (
        !this.form
          .validate()
          .errors()
          .any()
      ) {
        this.saveEFiscaux(this.form.data)
      } else {
        this.notif.message = "Veuillez proprement remplir tous les champs"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
        for (let afficheKey in this.affiche) {
          const tabVale = this.affiche[afficheKey].split(":")
          if (tabVale.length === 1) {
            this.affiche[afficheKey] = tabVale[0] + ":true"
          }
        }
      }
    },
    goBack() {
      this.$router.push({ name: "Comptabilite" })
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    }
  }
}
</script>
